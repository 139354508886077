// Theming
@import "../components/colors";
@import url("https://fonts.googleapis.com/css?family=Nunito");
$body-bg: $colorLight;
$body-color: $colorInverse;
// Typography
$font-family-sans-serif: "Nunito", sans-serif;
$font-size-base: 1rem;
$line-height-base: 1.6;
// Set theme colors for bootstrap
$theme-colors: (
    "primary": $colorPrimary,
    "secondary": $colorSecondary,
    "success": $colorSuccess,
    "info": $colorInfo,
    "warning": $colorWarning,
    "danger": $colorDanger,
    "light": $colorLight,
    "dark": $colorDark,
);
// Add custom colors to map
$custom-colors: (
    "tertiary": $colorTertiary,
    "inverse": $colorInverse,
    "faded": $colorFaded,
);
// Merge the maps
$theme-colors: map-merge($theme-colors, $custom-colors);