.navbar {
    // background-color: $colorFaded;
    padding-top: 0;
    padding-bottom: 0;
    .navbar-toggle {
        .icon-bar {
            background-color: $colorPrimary;
        }
    }
    .navbar-brand {
        padding-top: 0;
        padding-bottom: 0;
        > img {
            height: 100%;
        }
    }
    .navbar-nav li {
        a {
            color: $colorLight;
            font-weight: 500;
            &:hover,
            &:focus {
                color: $colorPrimary;
            }
        }
        &.active {
            & > a {
                color: $colorPrimary;

                &:hover,
                &:focus {
                    color: $colorPrimary;
                }
            }
        }
    }
}
@media (min-width: 768px) {
    .navbar-nav {
        > li > a {
            line-height: 30px;
            font-size: 16px;
        }
    }
}