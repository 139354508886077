.btn {
    border-radius: unset;
    // font-size: 1.3rem;
    // font-weight: 600;
    -ms-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    &.btn-primary {
        color: $colorLight;
        &:hover,
        &:focus {
            background-color: darken($colorPrimary, 2%);
        }
    }
    &.btn-outline-primary {
        color: $colorPrimary;
        background-color: transparent;
        &:hover,
        &:focus {
            color: darken($colorPrimary, 2%);
            border-color: darken($colorPrimary, 2%);
        }
    }
    &.btn-secondary {
        color: $colorDark;
        &:hover,
        &:focus {
            background-color: darken($colorSecondary, 2%);
        }
    }
    &.btn-outline-secondary {
        color: $colorSecondary;
        background-color: transparent;
        &:hover,
        &:focus {
            color: darken($colorSecondary, 2%);
            border-color: darken($colorSecondary, 2%);
        }
    }
}
